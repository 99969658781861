import { Link } from "gatsby"
import React, {useEffect} from "react"
import NoveltyCard from "../novelty-card";
import {getNoveltiesAction} from "../../redux/noveltiesDucks"
import {getThree,getFeatured,getNotFeatured} from '../../helpers/helper.novelty'
import ContentLoader from "react-content-loader"
import { connect } from "react-redux";
import { useGetBlogsQuery } from "../../redux/mediacore/blog";

const Novelties = ({loading,dispatch}) => {

    const { data: novelties, isLoading: isLoadingBlogData, isError } = useGetBlogsQuery(6);

    const featuredFirst = (data) => {
        let nov = [];
        if (getFeatured(data)?.length > 0) {
            nov.push(...getFeatured(data))
            nov.push(...getNotFeatured(data))
            return nov
        } else {
            return data
        }
    }

    return(
        <section class={"novelties py-5 mt-5 " + (!isLoadingBlogData && novelties?.news?.length > 0 ? '' : 'd-none')} >
            <div class="opacity"></div>
            <div class="container-fluid py-5">
                {/* {console.log(novelties)} */}
                {/* {console.log(isLoadingBlogData)} */}
                <div class="row">
                    <div class="col-12 col-lg-2">
                        <p class="section-name">NOVEDADES</p>
                    </div>
                    <div class="col-12 col-lg-10">
                        <h2 className="my-3 my-md-0">MANTENETE AL TANTO DE LAS ÚLTIMAS TENDENCIAS.</h2>
                    </div>
                    
                    <div class="col-12 col-lg-10 offset-0 offset-lg-2 pt-4">
                        <div class="row py-lg-5">     
                                 {
                                    isLoadingBlogData ?
                                        [1,2,3].map(count => (
                                            <div className="mb-4 mb-lg-0 col-12 col-lg-4">
                                                <div className='me-3 d-inline'>
                                                    <ContentLoader 
                                                        speed={2}
                                                        width={"100%"}
                                                        height={"46vh"}
                                                        viewBox="0 0 100% 46vh"
                                                        backgroundColor="#f3f3f3ee"
                                                        foregroundColor="#ecebebee"
                                                    >
                                                        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" /> 
                                                    </ContentLoader>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        getThree(featuredFirst(novelties?.news)).map((novelty, index) => (
                                            <div className="mb-4 mb-lg-0 col-12 col-lg-4" key={index}>
                                                <NoveltyCard isHome={true} novelty={novelty} hidenTags={true} hidenAuthor={true} />
                                            </div>
                                        ))
                                 }
                        </div>
                    </div>
                    <div class="col-12 col-lg-10 mt-5 offset-lg-2 text-start">
                        <Link to="novedades" class="btn btn-red">Ver todas las novedades</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default connect(state => ({
    loading: state.novelties.loading,
    grid_novelties: state.novelties.grid_novelties,
  }),null)(Novelties);