import { middlewareTokkoApi } from "../middlewareTokkoApi.js"

const propertiesExtended = middlewareTokkoApi.injectEndpoints({
  endpoints: builder => ({
    getAdvancedLocations: builder.query({
      query: ({API_KEY,custom_operation='',custom_types=''}) =>({
        headers: {
            'X-TOKKO-KEY': API_KEY
        },
        url: `properties/locations/?operation=${custom_operation}${custom_types.length > 0 ? "&tipo="+custom_types : ''}`,
        method: 'GET',
      }), 
    }),
    getFeaturedProperties: builder.query({
      query: ({API_KEY}) =>({
        headers: {
            'X-TOKKO-KEY': API_KEY
        },
        url: `properties/search?cache=0&save=0&limit=24&offset=0&destacados=1`,
        method: 'GET',
      }), 
    }),
    getProperties: builder.query({
      query: ({API_KEY,params_get,body}) =>({
        headers: {
            'X-TOKKO-KEY': API_KEY
        },
        url: `properties/search${params_get}`,
        body,
        method: 'POST',
      }), 
      // transformResponse: ({objects}) => objects,
    }),
    getProperty: builder.query({
      query: ({API_KEY,ID}) =>({
        headers: {
            'X-TOKKO-KEY': API_KEY
        },
        url: `properties/${ID}?cache=0`,
        method: 'GET',
      }), 
      // transformResponse: ({objects}) => objects,
    }),
    
  }),
  overrideExisting: false,
})

export const { useGetAdvancedLocationsQuery, useGetFeaturedPropertiesQuery,useGetPropertiesQuery,useGetPropertyQuery, usePrefetch } = propertiesExtended
