import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { getFavoritesAction,clearFavoritesAction, updaterFavoritesAction, deleteFavoriteAction } from '../redux/propertiesDucks';
import { useLocation } from '@reach/router'

// Components
import Loading from './Loading';
import toast from 'react-hot-toast';
import OwlCarousel from 'react-owl-carousel3';

// Helpers
import { getCover,getPreviewSlider } from '../helpers/helper.rendering';
import { addFavorites, getfavorites, checkFavorite } from '../helpers/helper.favorites';
import { getOperations, getType, getEnvironment, getSurface, getLocation, getFakeAddres, getPrices, makeLink } from '../helpers/helper.properties';

// SVG
import ArrowLeftSvg from '../images/svg/arrow-light-left.svg';
import ArrowRightSvg from '../images/svg/arrow-light-right.svg';

const StyleCard = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)
    const { property, skeleton } = props
    const { settings } = props
    const { dispatch } = props
    const [listFavorites,setListFavorites] = useState(getfavorites("prop",settings.short_name))
    const slider = useRef()
    const location = useLocation()
    const pathname = location.pathname.replaceAll('/','')

    const toastCheck = (id,fake_address) => {
        if(!checkFavorite(id,"prop",settings.short_name))
            return toast.success(fake_address + " agregada a tus favoritos") 
        else
            return toast.success(fake_address + " eliminada de tus favoritos")
    }

    const checkActive = (id) => {
        setListFavorites(getfavorites("prop",settings?.short_name))
        dispatch(clearFavoritesAction())
        for (const iterator of getfavorites("prop",settings?.short_name)) {
            //Por cada uno de los favoritos, traigo su respectiva data de la api
            dispatch(getFavoritesAction(iterator))
        }
    }

    // Format text function
    const formatDesc = (text, limit) => text.replaceAll("&nbsp;","").slice(0,limit) + "...";

    return (
        <div className={"card pcs position-relative " + (skeleton ? 'skeleton' : '')} id="property-card-styled">
            { (pathname !== '' || pathname?.length !== 0) 
                ? <Link to={makeLink(property)} className="clickeable-area position-absolute"/> : null }
            <div className="card-image arrows-container">
                {skeleton && <Loading />}
                {props.basic
                    ? <Link to={makeLink(property)}>
                        <img src={getCover(property?.photos)?.image } className='notScale' alt={realEstate?.name} />
                      </Link> 
                    : <>
                        {!skeleton && 
                            <OwlCarousel ref={slider}
                                items={1}
                                margin={0}
                                loop={true}
                                className="overflow-hidden card-carousel">
                                    {(getPreviewSlider(property?.photos)).map((photo, index) => (
                                        <Link to={makeLink(property)} key={index}>
                                            {<img src={index === 0 ? photo?.image : photo?.image} className='notScale' alt={realEstate?.name} />}
                                        </Link>))}
                            </OwlCarousel>}
                        {(getPreviewSlider(property?.photos)?.length > 1)
                            ? <>
                                <div className="arrow prev cursor-pointer" onClick={() => slider.current.prev()}>
                                    <ArrowLeftSvg className="arrow-prev cursor-pointer"/>
                                </div>
                                <div className="arrow next cursor-pointer" onClick={() => slider.current.next()}>
                                    <ArrowRightSvg className="arrow-next cursor-pointer"/>
                                </div>
                            </> : null }
                    </>}
                    {property
                        ? <div className="badges">
                            {getOperations(property).map((operation, index) => (
                                <div key={index} className={"badge " + operation.toLowerCase()}>{operation}</div>
                            ))}
                        </div> : null}
            </div>
            <div className="card-body card-body-styled position-absolute cb-parent">
                <div className="content-extra d-flex flex-column cb-child">
                    <Link to={makeLink(property)}>
                        <div className="environment mb-3">
                            <span>
                                {skeleton 
                                    ? 'Cargando....' 
                                    : <>
                                        {getType(property)} 
                                         · {getEnvironment(property)} ambiente{getEnvironment(property) > 1 ? 's' : ''}  
                                        {getSurface(property) ? (" · " + getSurface(property)): null}
                                    </>}
                            </span>
                        </div>
                        <h3 className="card-title mb-2">
                            {skeleton ? 'Cargando....' : getFakeAddres(property)}
                        </h3>
                    </Link>
                    <div className="row row-info d-flex justify-content-between align-items-center align-items-lg-end">
                    {skeleton 
                        ? <>
                            <div className="col-8 loc-desc"></div>
                            <div className="col-4 text-lg-end d-flex justify-content-end align-items-center">
                                <p className="prop-price me-lg-3"></p>
                                <span className="d-inline-flex btn fav"></span>
                            </div>
                        </>
                        : <>
                            <div className="col-7 col-lg-8 loc-desc">
                                <Link to={makeLink(property)}>
                                    <p className="location mt-2">
                                        {getLocation(property)}
                                    </p>
                                    {(property?.publication_title?.length > 0)
                                        ?<p className="description mt-2">
                                            {formatDesc(property?.publication_title, 90)}
                                        </p> : null }
                                </Link>
                            </div>
                            <div className="col-5 col-lg-4 text-lg-end d-flex justify-content-end align-items-center">
                                {getPrices(property).map((price, index) => (
                                <p className={"prop-price me-lg-2 " + price.type } key={index}>
                                    {price.currency} {Intl.NumberFormat("de-DE").format(price.price)}</p>))}
                                <span onClick={() => dispatch(updaterFavoritesAction()) 
                                    + toastCheck(property?.id, property?.fake_address) 
                                    + addFavorites(property?.id,"prop",settings?.short_name) 
                                    + setListFavorites(getfavorites("prop",settings?.short_name)) 
                                    + checkActive(property?.id)
                                    + dispatch(deleteFavoriteAction(property?.id))} 
                                    className={"d-inline-flex btn fav " + (listFavorites.find(element => element.toString() === property?.id?.toString()) ? 'icon-solid-like active' : 'icon-like')} style={{zIndex:"9999"}}></span>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(state => ({
    settings: state.settings,
}),null)(StyleCard);