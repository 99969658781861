import { Link } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import Searcher from "../search"
import { connect } from "react-redux"
import { getDevelopmentsAction } from "../../redux/developmentsDucks.js"
import { getTotalListFilters } from "../../redux/propertiesDucks"
import Loading from "../Loading"
import OwlCarousel from "react-owl-carousel3"

//Helpers
import { getLocation, getLastFeatured } from "../../helpers/helper.developments"
import { getCover } from "../../helpers/helper.rendering"
import { graphql, useStaticQuery } from "gatsby"
import {
  getActionByPage,
  getActionByType,
  getCodeFromType,
  getCodes,
} from "../../helpers/helper.actions"
import { informCustomEvent } from "../../helpers/helper.analytics"
import { useGetPushQuery } from "../../redux/mediacore/push"
import { useGetDevelopmentsQuery } from "../../redux/middlewareTokkoApi/developments"

const Home_Main = () => {
  const slider = useRef()

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        slogan
        main_image
        keys {
          tokko
        }
      }
    }
  `)

  const { data: actions, isLoading: loading } = useGetPushQuery(6)
  const { data: allDevelopmentsData, isLoading: isLoadingDevelopments } =
    useGetDevelopmentsQuery(realEstate.keys.tokko)

  const slider_action = getActionByPage(
    getActionByType(actions, getCodeFromType("Slider de Imagenes Encabezado")),
    "INI"
  )
  const image_action = getActionByPage(
    getActionByType(actions, getCodeFromType("Imagen Encabezado")),
    "INI"
  )
  const video_action = getActionByPage(
    getActionByType(actions, getCodeFromType("Video Encabezado")),
    "INI"
  )

  const makeLink = development => {
    if (development) {
      return "/emprendimientos/" + development.id
    }
  }

  // Initial state  for window size
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  // Hook on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const getSize = () => (screen.width < 992 ? false : true)

  const goTo = () => {
    if (video_action[0].url_destiny) {
      informCustomEvent(
        "PUSH_" +
          getCodes(video_action[0].type, video_action[0].section) +
          "_" +
          video_action[0].name
      )
      window.open(
        video_action[0].url_destiny + video_action[0].text_utm,
        "_blank",
        "noopener,noreferrer"
      )
    }
  }

  useEffect(() => {
    if (video_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(video_action[0]?.type, video_action[0]?.section) +
          "_" +
          video_action[0]?.name
      )
    }
    if (image_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(image_action[0]?.type, image_action[0]?.section) +
          "_" +
          image_action[0]?.name
      )
    }
    if (slider_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(slider_action[0]?.type, slider_action[0]?.section) +
          "_" +
          slider_action[0]?.name
      )
    }
  }, [])

  // Banner Featured with Searcher
  const bannerFeatured = () => {
    return (
      <div
        id="home-main"
        class="banner-main"
        style={{
          backgroundImage:
            "url(" +
            (!isLoadingDevelopments
              ? getCover(getLastFeatured(allDevelopmentsData).photos)?.image
              : realEstate?.main_image) +
            ")",
        }}
      >
        <div class="item d-flex justify-content-center text-center align-items-center">
          <div class="opacity"></div>
          <div class="container" style={{ zIndex: "1" }}>
            <div class="row align-items-center text-center justify-content-center">
              <div class="col-12 pb-5">
                <p>
                  {!isLoadingDevelopments &&
                    getLocation(getLastFeatured(allDevelopmentsData), true)[0]}
                  <br />
                  {!isLoadingDevelopments &&
                    getLocation(getLastFeatured(allDevelopmentsData), true)[1]}
                </p>
                <h1>
                  {!isLoadingDevelopments &&
                    getLastFeatured(allDevelopmentsData).name}
                </h1>
                <a
                  href={
                    !isLoadingDevelopments &&
                    makeLink(getLastFeatured(allDevelopmentsData))
                  }
                  target="_blank"
                  class="btn btn-blue mb-5"
                >
                  DESCUBRILO
                </a>
              </div>
            </div>
          </div>
        </div>
        <Searcher />
      </div>
    )
  }

  return !isLoadingDevelopments && !loading ? (
    slider_action.length > 0 || video_action.length > 0 ? ( //Caso Actions video/slider
      <>
        {slider_action.length > 0 && (
          <>
            <div id="section-galery-home">
              <div id="dev-secuencial">
                <div className="slider" id="dev-gallery">
                  <OwlCarousel
                    items={1}
                    ref={slider}
                    margin={0}
                    nav={false}
                    dots={true}
                    loop={true}
                    rewind={true}
                    autoplay={true}
                    autoplayTimeout={
                      parseInt(slider_action[0]?.transition_time) * 1000
                    }
                    id="prop-gallery"
                  >
                    {slider_action[0].files.map((file, index) => (
                      <div
                        className="item banner-main banner-ficha emprendimiento"
                        key={index}
                      >
                        <img
                          src={file.file}
                          className="object-cover object-center cursor-pointer"
                          alt={realEstate?.name}
                        />
                        <div className="info-banner position-absolute">
                          <div className="info row align-items-center text-center justify-content-center">
                            <div className="col-8 col-lg-12">
                              <a
                                target={"_blank"}
                                href={
                                  file.url_destiny + slider_action[0].text_utm
                                }
                                onClick={() =>
                                  informCustomEvent(
                                    "PUSH_" +
                                      getCodes(
                                        slider_action[0]?.type,
                                        slider_action[0]?.section
                                      ) +
                                      "_" +
                                      slider_action[0].name
                                  )
                                }
                                className={
                                  "link-gallery " +
                                  (!file.url_destiny && "d-none")
                                }
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                  <div className="arrows-owl">
                    <div
                      className="arrow"
                      onClick={() => slider.current.prev()}
                    >
                      <div className="next">
                        <i className="icon-arrow-right"> </i>
                      </div>
                    </div>
                    <div
                      className="arrow "
                      onClick={() => slider.current.next()}
                    >
                      <div className="next">
                        <i className="icon-arrow-right"> </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Searcher />
            </div>
          </>
        )}
        {video_action.length > 0 && (
          <>
            {windowSize?.width > 992 ? (
              <div
                className={
                  "banner-main " +
                  (video_action[0].url_destiny === ""
                    ? "cursor-default"
                    : "cursor-pointer")
                }
                onClick={() => goTo()}
              >
                <video loop autoPlay muted>
                  <source
                    src={
                      video_action[0]?.files?.filter(
                        video => video.device === "desktop"
                      )?.[0].file ?? ""
                    }
                    type="video/mp4"
                  />
                </video>
                <Searcher />
              </div>
            ) : (
              bannerFeatured()
            )}
          </>
        )}
      </>
    ) : (
      // Casos sin actiones (Con emprendimientos o sin).
      <>
        {allDevelopmentsData?.length > 0 &&
        !!image_action &&
        getLastFeatured(allDevelopmentsData) ? (
          bannerFeatured()
        ) : (
          <div
            id="home-main"
            onClick={() =>
              image_action[0].url_destiny
                ? window.open(
                    image_action[0].url_destiny + image_action[0].text_utm,
                    "_blank",
                    "noopener,noreferrer"
                  ) +
                  informCustomEvent(
                    "PUSH_" +
                      getCodes(image_action[0].type, image_action[0].section) +
                      "_" +
                      image_action[0].name
                  )
                : ""
            }
            class={
              "banner-main " +
              (image_action.length === 0 || image_action[0]?.url_destiny === ""
                ? "cursor-default"
                : "")
            }
            style={{
              backgroundImage:
                "url(" +
                (image_action.length > 0
                  ? image_action[0]?.files[0]?.file
                  : realEstate?.main_image) +
                ")",
            }}
          >
            {image_action.length === 0 && (
              <div class="item d-flex justify-content-center text-center align-items-center">
                <div class="opacity"></div>
                <div class="container" style={{ zIndex: "1" }}>
                  <div class="row align-items-center text-center justify-content-center">
                    <div class="col-12 pb-5">
                      <h1>{realEstate?.slogan}</h1>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Searcher />
          </div>
        )}
      </>
    )
  ) : (
    <div id="home-main" class={"banner-main isHome skeleton"}>
      <Loading></Loading>
      <Searcher />
    </div>
  )
}

export default connect(
  state => ({
    developments: state.developments.developments,
    actions: state.actions.actions,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko,
  }),
  null
)(Home_Main)
